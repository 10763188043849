<template>
  <div id="user-view">
    <div class="text-center" v-if="loading">
      <v-progress-circular
        :size="50"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </div>
    <v-form
      v-else
      ref="formRef"
      @submit.prevent
    >
      <v-card class="mb-5">
        <v-row class="ma-0 pa-0">
          <v-col
            cols="8"
            sm="12"
            class="pa-0"
          >
            <v-card-title>Kampagne</v-card-title>
            <v-card-text>
                <v-text-field
                  v-model="form.mainData.name"
                  label="Name"
                  type="text"
                  outlined
                  dense
                  hide-details="auto"
                  placeholder="Name"
                  required
                  :rules="[v => !!v || 'Name vergeben']"
                ></v-text-field>
            </v-card-text>
          </v-col>
        </v-row>
      </v-card>
      <v-row class="mb-3">
        <v-col
          cols="12"
          md="12"
          lg="12"
        >
          <v-row class="user-bio-panel">
            <v-col cols="12">
              <v-card class="">
                <v-card-title>Selektionen</v-card-title>
                <v-card-text class=" justify-center flex-column mb-0 ">
                  <v-row>
                    <v-col
                      cols="12"
                      md="8"
                    >
                      <v-autocomplete
                        class="mb-3"
                        v-model="form.mainData.idPtZielgruppe"
                        hide-details="auto"
                        outlined
                        dense
                        :items="targetGroups"
                        item-text="displayName"
                        item-value="idPtZielgruppe"
                        label="Zielgruppe auswählen"
                        @change="targetGroupHandler"
                        :rules="[validators.required]"
                      >
                        <template v-slot:item="{ item, on, attrs }">
                          <v-list-item
                            v-bind="attrs"
                            v-on="on"
                          >
                            <v-list-item-content>
                              <v-list-item-title class="d-flex justify-space-between">
                                {{ item.displayName }}
                                <span>
                                  <span class="mr-3" v-if="item.datenquelle == 'fremderUpload'">{{ item.customerDisplayName }}</span>
                                    <v-icon size="18" v-if="item.datenquelle == 'bestandsdaten'">
                                    {{ icons.mdiBullseyeArrow }}
                                  </v-icon>
                                  <v-icon size="18" v-if="item.datenquelle == 'eigenerUpload'">
                                    {{ icons.mdiCloudUpload }}
                                  </v-icon>
                                  <v-icon size="18" v-if="item.datenquelle == 'fremderUpload'" >
                                    {{ icons.mdiShareVariantOutline }}
                                  </v-icon>
                                </span>
                              </v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </template>
                        <template v-slot:selection="{ item }">
                          <span class="d-flex justify-space-between" style="width:100%">
                            <span>{{ item.displayName }}</span>
                            <span >
                               <span class="mr-3" v-if="item.datenquelle == 'fremderUpload'">{{ item.customerDisplayName }}</span>
                              <v-icon size="18" v-if="item.datenquelle == 'bestandsdaten'">
                                {{ icons.mdiBullseyeArrow }}
                              </v-icon>
                              <v-icon size="18" v-if="item.datenquelle == 'eigenerUpload'">
                                {{ icons.mdiCloudUpload }}
                              </v-icon>
                              <v-icon size="18" v-if="item.datenquelle == 'fremderUpload'">
                                {{ icons.mdiShareVariantOutline }}
                              </v-icon>
                            </span>
                          </span>
                        </template>
                      </v-autocomplete>

                      <v-autocomplete
                        class="mb-3"
                        v-model="form.mainData.idPtWerbemittel"
                        hide-details="auto"
                        outlined
                        dense
                        :items="adMaterials"
                        item-text="displayName"
                        item-value="idPtWerbemittel"
                        label="Werbemittel auswählen"
                        @change="adMaterialHandler"
                        :rules="[validators.required]"
                      ></v-autocomplete>

<!--                      <v-slider
                        label="Max."
                        v-model="form.mainData.maxEmpfaenger"
                        class="mt-3 flex align-center"
                        hide-details
                        :max="numberOfPotentialDatasets"
                        :rules="form.mainData.idPtZielgruppe > 0 ? [validators.required] : []"
                      >
                        <template #append>
                          <span class="align-center">{{form.mainData.maxEmpfaenger}}</span>
                        </template>
                      </v-slider>-->

                      <v-text-field
                        type="number"
                        label="Anzahl Empfänger (Mindestmenge: 350 Empfänger)"
                        v-model="form.mainData.maxEmpfaenger"
                        class="mt-3 mb-3 flex align-center"
                        hide-details="auto"
                        outlined
                        dense
                        :max="numberOfPotentialDatasets"
                        :rules="form.mainData.idPtZielgruppe > 0 ? [validators.minValueRule(350)] : []"
                        @change="handleMaxEmpfaenger"
                      ></v-text-field>

                      <v-alert
                        border="left"
                        colored-border
                        text
                        color="info"
                        style="font-size:12px"
                      >
                        Bei der Reduzierung der Daten bleiben die Datensätze mit der höchsten Übereinstimmung erhalten.
                      </v-alert>
                    </v-col>
                    <v-col
                      cols="12"
                      md="4"
                    >
                      <vue-apex-charts
                        id="chart-sales-overview"
                        :options="chartOptions"
                        :series="chartData"
                        class="d-flex align-center justify-center"
                        width="60%"
                      ></vue-apex-charts>

                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-row class="mb-3 align-stretch" v-if="selectedAdMaterial.idPtWerbemittel > 0" align-stretch>
        <v-col
          cols="12"
          md="12"
          lg="12"
        >
          <v-card class="fill-height">
            <v-card-title>Vorschau - Vorderseite</v-card-title>
            <v-card-text class=" justify-center flex-column mb-0 ">
              <v-row>
                <v-col
                  cols="12"
                  md="12"
                >
                  <vue-pdf-with-draggable
                    :src="selectedAdMaterial.dateiVorderseite.url"
                    :srcLocale="selectedAdMaterial.dateiVorderseite.urlLocale"
                    :key="selectedAdMaterial.randomKey"
                    :pageWidth="selectedAdMaterial.dateiVorderseite.dimension.width"
                    :pageHeight="selectedAdMaterial.dateiVorderseite.dimension.height"
                    :text="form.mainData.angabeQrcodeEinfuegen ? form.mainData.qrcodeInhalt: ''"
                    :posX="form.mainData.qrcodeEinstellungen.posXInMM"
                    :posY="form.mainData.qrcodeEinstellungen.posYInMM"
                    :rotation="form.mainData.qrcodeEinstellungen.rotationInDeg"
                    :width="form.mainData.qrcodeEinstellungen.widthInMM"
                    :useMovement="true"
                    :useSquareContainer="true"
                    :useWidthAndHeightHandle="true"

                    @update:coordinates="handleQrCodeCoordinatesUpdate"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="12"
                  v-if="form.mainData.angabeQrcodeEinfuegen"
                >
                  <v-text-field
                    v-model="form.mainData.qrcodeInhalt"
                    label="QR-Code Inhalt"
                    type="text"
                    outlined
                    dense
                    hide-details="auto"
                    placeholder="QR-Code Inhalt"
                    required
                    :rules="[stringRules]"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>

        </v-col>
        <v-col
          cols="12"
          md="12"
          lg="12"
        >
          <v-card class="fill-height">
                <v-card-title>Vorschau - Rückseite</v-card-title>
                <v-card-text class=" justify-center flex-column mb-0 ">

                  <v-row>

                    <v-col
                      cols="12"
                      md="12"
                    >

                      <vue-pdf-with-draggable
                        :src="selectedAdMaterial.dateiRueckseite.url"
                        :srcLocale="selectedAdMaterial.dateiRueckseite.urlLocale"
                        :text="selectedTargetGroup ? form.mainData.datenschutzText : ''"
                        :posX="form.mainData.datenschutzTextEinstellungen.posXInMM"
                        :posY="form.mainData.datenschutzTextEinstellungen.posYInMM"
                        :rotation="form.mainData.datenschutzTextEinstellungen.rotationInDeg"
                        :fontSize="form.mainData.datenschutzTextEinstellungen.fontSizeInMM"
                        :paddingLeft="form.mainData.datenschutzTextEinstellungen.paddingLeftInMM"
                        :paddingRight="form.mainData.datenschutzTextEinstellungen.paddingRightInMM"
                        :paddingTop="form.mainData.datenschutzTextEinstellungen.paddingTopInMM"
                        :paddingBottom="form.mainData.datenschutzTextEinstellungen.paddingBottomInMM"
                        :lineHeight="form.mainData.datenschutzTextEinstellungen.lineHeightInMM"
                        :width="form.mainData.datenschutzTextEinstellungen.widthInMM"
                        :pageWidth="selectedAdMaterial.dateiRueckseite.dimension.width"
                        :pageHeight="selectedAdMaterial.dateiRueckseite.dimension.height"
                        :key="selectedTargetGroup.idPtZielgruppe+'_'+selectedAdMaterial.idPtWerbemittel"

                        :useMovement="true"
                        :useFontHandle="true"
                        :useWidthHandle="true"
                        :useRotationHandle="true"
                        :useStampAndRecipientZone="true"
                        :useCodingZone="true"

                        :exampleRecipient="selectedTargetGroup.firstRecipient"

                        @update:coordinates="handleCoordinatesUpdate"
                        ></vue-pdf-with-draggable>


                    </v-col>
                  </v-row>

                  <v-row v-if="selectedTargetGroup.idPtZielgruppe > 0 && selectedTargetGroup.datenquelle == 'eigenerUpload'" >
                    <v-col
                      cols="12"
                      md="12"

                    >
                      <v-textarea
                        class="mt-3"
                        maxlength="300"
                        v-model="form.mainData.datenschutzText"
                        label="Datenschutztext"
                        type="text"
                        outlined
                        rows="5"
                        dense
                        hide-details="auto"
                        required
                        :rules="[stringRules]"
                        :error-messages="errorMessages.titel"
                      ></v-textarea>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>

        </v-col>
      </v-row>

      <CostEstimateCard :totalSums="totalSums"/>

      <v-row>
        <v-col
          cols="12"
          md="12"
          lg="12"
        >
          <v-card>
            <v-card-title>Versand-Datum</v-card-title>
            <v-card-text>
              <inline-date-picker v-model="form.mainData.zeitVersandStart" label="Start" :rules="dateRules()" :allowed-dates="allowedDates"/>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>


      <v-row>
        <v-col cols="12">
          <v-card>
            <v-card-title>
              <v-switch
                v-model="form.mainData.angabeIndividuelleMetaDaten"
                inset
                dense
                hide-details
              ></v-switch>
              Erweiterte Optionen
            </v-card-title>
            <v-card-text v-if="form.mainData.angabeIndividuelleMetaDaten">
              <v-row>
                <v-col cols="12" md="12">
                  <v-text-field
                    v-model="form.mainData.individuelleMetaDaten.Ekp"
                    label="Eigene EKP-Nummer angeben"
                    type="text"
                    outlined
                    dense
                    hide-details="auto"
                    placeholder="Eigene EKP-Nummer angeben"
                    required
                    :rules="[v => !!v || 'Eigene EKP-Nummer angeben']"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row v-if="form.mainData.angabeKorrekturAngefordert">
        <v-col cols="12">
          <v-card>
            <v-card-title>Korrektur beantragt - {{form.mainData.zeitKorrekturAngefordertDE}}</v-card-title>
            <v-card-text>
              <v-alert
                border="left"
                colored-border
                text
                color="warning"
              >
                <pre>{{form.mainData.korrekturBeschreibung}}</pre>
              </v-alert>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row class="mt-5">
        <v-col cols="12" md="12">
          <router-link :to="'/kampagne'">
            <v-btn
              color="secondary"
              class="mx-2"
            >
              Zurück
            </v-btn>
          </router-link>
          <v-btn
            color="accent"
            class="mx-2"
            type="submit"
            @click="saveData(false)"
          >
            Speichern
          </v-btn>

          <v-btn
            v-if="form.mainData.angabeKorrekturAngefordert"
            color="warning"
            class="mx-2"
            type="submit"
            @click="saveData(true)"
          >
            <span class="--text">Speichern und Korrektur bestätigen</span>
          </v-btn>

          <v-btn
            v-if="form.aktion == 'createCampaignCheck' || form.mainData.status == 'inBearbeitung' || form.mainData.status == 'inBearbeitungKorrektur'"
            color="success"
            class="mx-2"
            type="button"
            @click="saveData(form.mainData.angabeKorrekturAngefordert,true)"
          >
            Sofort freigeben und bezahlen

            <v-icon size="18" class="ml-3">
              {{ icons.mdiCartOutline }}
            </v-icon>
          </v-btn>


        </v-col>
      </v-row>

    </v-form>
  </div>
</template>

<script>
import {onMounted, ref, getCurrentInstance,nextTick,watch} from '@vue/composition-api'
import router from '@/router'
import {
  mdiHomeOutline,
  mdiEmailOutline,
  mdiLockOutline,
  mdiEyeOffOutline,
  mdiEyeOutline,
  mdiCalendarOutline,
  mdiClockOutline,
  mdiRefresh,
  mdiCartOutline,
  mdiShareVariantOutline,
  mdiBullseyeArrow,
  mdiCloudUpload
} from '@mdi/js'

import axios from "axios";
import {emailValidator, required, maxValueRule, minValueRule} from "@core/utils/validation";
import {addAlpha, getVuetify} from "@core/utils";
import VuePdfWithDraggable from "@/views/VuePdfWithDraggable.vue";
import isBlob from 'is-blob';
import InlineDatePicker
  from "@/views/InlineDatePicker";
import PushNotificationPreview from "@/views/push-notification/Preview";
import VuetifyDatetimePickerWithErrorHandling from "@/views/VuetifyDatetimePickerWithErrorHandling";
import CostEstimateCard from "@/views/CostEstimateCard";
import themeConfig from '@themeConfig'
import moment from 'moment';
import StatisticsCardProfitLineChart
  from "@core/components/statistics-card/StatisticsCardProfitLineChart";
import Holidays from 'date-holidays';

export default {
  components: {
    StatisticsCardProfitLineChart,
    PushNotificationPreview,
    VuetifyDatetimePickerWithErrorHandling,
    VueApexCharts: () => import('vue-apexcharts'),
    InlineDatePicker,
    CostEstimateCard,
    VuePdfWithDraggable
  },
  data() {
    return {
      showPreview: true
    }
  },

  setup(props) {

    const userTab = ref(null)
    const tabs = ref([
      { icon: mdiHomeOutline, title: 'Übersicht', id:'overview' },
    ])

    const vm = getCurrentInstance().proxy
    vm.$toastr.defaultTimeout = themeConfig.toastr.defaultTimeout;
    vm.$toastr.defaultPosition = themeConfig.toastr.defaultPosition;

    const formRef = ref(null)
    const form = ref({})

    const freieAuswahlDefault = {

    };

    const stringRules = (value) => !!value.trim() || 'Bitte ausfüllen';


    const totalSums = ref({})

    const maxEmpfaengerChangeContext = ref("");
    const handleMaxEmpfaenger = () => {
      maxEmpfaengerChangeContext.value = "user";
      fetchNumberOfPotentialDatasets(null,form.value.mainData.maxEmpfaenger)
    }

    const errorMessages = ref({
      zeitVersandStart:''
    });

    const dateRules = () => {
      return [
        (value) => !!value || 'Versandstart wählen.',
        (value) => {
          if (!moment(value, 'DD.MM.YYYY', true).isValid()) {
            return 'Falsches Format: dd.mm.YYYY';
          }
          return true;
        },
      ];
    }

    const selectedTargetGroup = ref({
      idPtZielgruppe : 0,
      datenschutzText : '',
      datenquelle : 'bestandsdaten',
      firstRecipient : '',
    });
    const targetGroupHandler = () => {
      fetchNumberOfPotentialDatasets();
      axios.get('/api/ajaxServer/?aktion=fetchTargetGroup&idPtZielgruppe='+form.value.mainData.idPtZielgruppe+'&maxEmpfaenger='+form.value.mainData.maxEmpfaenger).then((response) => {
        selectedTargetGroup.value = response.data.targetGroup
        form.value.mainData.datenschutzText = selectedTargetGroup.value.datenschutzText;
        let tmpId = selectedAdMaterial.value.idPtWerbemittel;
        selectedAdMaterial.value.idPtWerbemittel = 0;
        selectedAdMaterial.value.idPtWerbemittel = tmpId;
      })
    }

    const selectedAdMaterial = ref({
      randomKey : 0,
      dateiVorderseite : '',
      dateiRueckseite : '',
      datenschutzTextEinstellungen : {
        rotationInDeg: 0,
        fontSizeInMM: 2,
        posXInMM: 3,
        posYInMM: 3,
        paddingLeftInMM: 3,
        paddingRightInMM: 3,
        paddingTopInMM: 3,
        paddingBottomInMM: 3,
        lineHeightInMM: 2,
        widthInMM: 55
      },
      qrcodeEinstellungen : {
        text: "QR-Code",
        rotationInDeg: 0,
        posXInMM: 3,
        posYInMM: 3,
        widthInMM: 20
      },
      angabeQrcodeEinfuegen:false,
      qrcodeInhalt:"",
    });
    const adMaterialHandler = () => {
      axios.get('/api/ajaxServer/?aktion=fetchAdMaterial&idPtWerbemittel='+form.value.mainData.idPtWerbemittel).then((response) => {
        selectedAdMaterial.value = response.data.adMaterial
        form.value.mainData.datenschutzTextEinstellungen = {...selectedAdMaterial.value.datenschutzTextEinstellungen}
        form.value.mainData.qrcodeEinstellungen = {...selectedAdMaterial.value.qrcodeEinstellungen}
        form.value.mainData.qrcodeInhalt = selectedAdMaterial.value.website
        form.value.mainData.angabeQrcodeEinfuegen = selectedAdMaterial.value.angabeQrcodeEinfuegen
        if(!selectedTargetGroup.value.idPtZielgruppe){
          form.value.mainData.datenschutzText = JSON.parse(JSON.stringify(selectedAdMaterial.value.datenschutzTextEinstellungen.text));
        }
      })
    }

    form.value = {
      mainData : {
        idPtKampagne : router.currentRoute.params.idPtKampagne,
        idPtZielgruppe : 0,
        idPtWerbemittel : 0,
        name : '',
        zeitVersandStart : '',
        korrekturBeschreibung : '',
        zeitKorrekturAngefordertDE : '',
        angabeKorrekturAngefordert : false,
        maxEmpfaenger : 0,
        datenschutzText : '',
        datenschutzTextEinstellungen : {
          text: "",
          rotationInDeg: 0,
          fontSizeInMM: 2,
          posXInMM: 0,
          posYInMM: 0,
          paddingLeftInMM: 1,
          paddingRightInMM: 1,
          paddingTopInMM: 1,
          paddingBottomInMM: 1,
          lineHeightInMM: 2,
          widthInMM: 55
        },
        qrcodeEinstellungen : {
          text: "QR-Code",
          rotationInDeg: 0,
          posXInMM: 3,
          posYInMM: 3,
          widthInMM: 20
        },
        qrcodeInhalt:"",
        angabeIndividuelleMetaDaten:false,
        individuelleMetaDaten:{
          Ekp : "",
        }
      },
      aktion: (router.currentRoute.params.idPtKampagne !== undefined ? 'editCampaignCheck' : 'createCampaignCheck'),
    };

    const handleCoordinatesUpdate = (coordinates) => {
      //console.log('Updated coordinates in mm:', coordinates);
      form.value.mainData.datenschutzTextEinstellungen = coordinates;
    }

    const handleQrCodeCoordinatesUpdate = (coordinates) => {
      //console.log('Updated coordinates in mm:', coordinates);
      form.value.mainData.qrcodeEinstellungen = coordinates;
    }


    /*onMounted(() => {
      targetGroupHandler()
      adMaterialHandler()
    })*/

    /*watch(() => form.value.mainData.maxEmpfaenger, (newValue, oldValue) => {


     /!* const maxAllowed = JSON.parse(JSON.stringify(numberOfPotentialDatasets.value));


      chartData.value = [form.value.mainData.maxEmpfaenger];


      if (newValue > maxAllowed) {
        nextTick(() => {
          form.value.mainData.maxEmpfaenger = maxAllowed;
          chartData.value = [form.value.mainData.maxEmpfaenger];
        })
      }*!/

      console.log('form.value.mainData.maxEmpfaenger',form.value.mainData.maxEmpfaenger)

      console.log('maxEmpfaengerChangeContext.value',maxEmpfaengerChangeContext.value)
      if(maxEmpfaengerChangeContext.value == "user"){
        fetchNumberOfPotentialDatasets(form.value.mainData.maxEmpfaenger);
      }

    });*/

    const loading = ref(true)
    const numberOfPotentialDatasets = ref(0)
    const targetGroups = ref([]);
    const adMaterials = ref([]);

    onMounted(()=>{

      axios.get('/api/ajaxServer/?aktion=fetchTargetGroups').then((response) => {
        targetGroups.value = response.data.targetGroups
        console.log(targetGroups.value)
      })
      axios.get('/api/ajaxServer/?aktion=fetchAdMaterials').then((response) => adMaterials.value = response.data.adMaterials)

      if(form.value.aktion == 'editCampaignCheck'){
        axios.post('/api/kampagne/', {
          aktion: 'editCampaign',
          mainData:{
            idPtKampagne : router.currentRoute.params.idPtKampagne,
          }
        })
          .then((response) => {
            if (response.data.valid === true) {

              response.data.campaign.zeitVersandStart = response.data.campaign.zeitVersandStart

              form.value.mainData = response.data.campaign;
              selectedTargetGroup.value = response.data.campaign.targetGroup;

              selectedAdMaterial.value = response.data.campaign.adMaterial
              selectedAdMaterial.value.datenschutzTextEinstellungen = {...response.data.campaign.datenschutzTextEinstellungen}
              selectedAdMaterial.value.qrcodeEinstellungen = {...response.data.campaign.qrcodeEinstellungen}


            } else {
              response.data.errors.forEach(item=>{
                let suffix = item.errorField != "" ? "("+item.errorField+")" : "";
                vm.$toastr.e(item.errorText, item.errorTitle+suffix);
              })
            }

            fetchNumberOfPotentialDatasets();


            loading.value = false;
          })
          .catch((error) => {
            console.log(error)
            loading.value = true;
          })
      }
      else{
        fetchNumberOfPotentialDatasets();
        loading.value = false;
      }
    })

    const saveData = (unsetCorrectionRequest,startPaymentWithAjax) => {
      let isFormValid = formRef.value.validate()
      if(!isFormValid) {
        nextTick(() => {
          const firstErrorField = document.querySelector('.error--text');
          if (firstErrorField) {
            firstErrorField.scrollIntoView({ behavior: 'smooth', block: 'center' });
          }
        });
        return
      }
      loading.value = true;

      const fd = new FormData();
      fd.append('aktion',form.value.aktion);
      fd.append('mainData[idPtKampagne]',form.value.mainData.idPtKampagne);
      fd.append('mainData[idPtZielgruppe]',form.value.mainData.idPtZielgruppe);
      fd.append('mainData[idPtWerbemittel]',form.value.mainData.idPtWerbemittel);
      fd.append('mainData[name]',form.value.mainData.name);
      fd.append('mainData[datenschutzText]',form.value.mainData.datenschutzText);
      fd.append('mainData[datenschutzTextEinstellungen]',JSON.stringify(form.value.mainData.datenschutzTextEinstellungen));
      fd.append('mainData[angabeIndividuelleMetaDaten]',form.value.mainData.angabeIndividuelleMetaDaten);
      fd.append('mainData[individuelleMetaDaten]',JSON.stringify(form.value.mainData.individuelleMetaDaten));
      fd.append('mainData[qrcodeEinstellungen]',JSON.stringify(form.value.mainData.qrcodeEinstellungen));
      fd.append('mainData[angabeQrcodeEinfuegen]',form.value.mainData.angabeQrcodeEinfuegen);
      fd.append('mainData[qrcodeInhalt]',form.value.mainData.qrcodeInhalt);
      fd.append('mainData[zeitVersandStart]',form.value.mainData.zeitVersandStart);

      if(unsetCorrectionRequest){
        fd.append('mainData[angabeKorrekturAbgeschlossen]',true);
      }
      else{
        fd.append('mainData[angabeKorrekturAngefordert]',form.value.mainData.angabeKorrekturAngefordert);
      }

      if(startPaymentWithAjax){
        fd.append('startPaymentWithAjax',true);
      }

      fd.append('mainData[maxEmpfaenger]',form.value.mainData.maxEmpfaenger);

      axios
        .post('/api/kampagne/',fd)
        .then(response => {
          if(response.data.valid === true){
            if(startPaymentWithAjax && response.data.checkoutUrl){
              window.location.href = response.data.checkoutUrl;
            }
            else{
              router.push('/kampagne',()=>{
                vm.$toastr.s(response.data.message.text, response.data.message.title);
              })
            }

          }
          else{
            response.data.errors.forEach(item=>{
              let suffix = item.errorField != "" ? "("+item.errorField+")" : "";
              vm.$toastr.e(item.errorText, item.errorTitle+suffix);
            })
          }
          loading.value = false;
        })
        .catch(error => {
          vm.$toastr.e(error,error);
          loading.value = false;
        })
    }

    const fetchNumberOfPotentialDatasetsBAK = (onLoad) =>{

      let potenzial = 0;
      targetGroups.value.forEach(item => {
        if(item.idPtZielgruppe == form.value.mainData.idPtZielgruppe){
          potenzial = item.anzahlEmpfaenger;
        }
      })

      numberOfPotentialDatasets.value = potenzial;
      if(!onLoad){
        form.value.mainData.maxEmpfaenger = potenzial;
      }
      chartData.value = [form.value.mainData.maxEmpfaenger];
    }

    const updateMainData  = (key,value) => {
      nextTick(() => {
        form.value.mainData[key] = value;
      })
    }

    const fetchNumberOfPotentialDatasets = (event,maxEmpfaenger) =>{

      if(form.value.mainData.idPtZielgruppe > 0){

        let fd = new FormData();
        fd.append('aktion','fetchNumberOfPotentialDatasets');
        fd.append('idPtZielgruppe', form.value.mainData.idPtZielgruppe);

        if(maxEmpfaenger){
          fd.append('maxEmpfaenger', maxEmpfaenger);
        }

        chartData.value = ["lädt..."];
        axios
          .post('/api/ajaxServer/',fd)
          .then(response => {

            numberOfPotentialDatasets.value = response.data.numberOfPotentialDatasets;
            totalSums.value = response.data.totalSums;

            chartData.value = [numberOfPotentialDatasets.value];

            form.value.mainData.anzahlEmpfaenger = response.data.numberOfPotentialDatasets;
            form.value.mainData.maxEmpfaenger = response.data.numberOfPotentialDatasets;
            maxEmpfaengerChangeContext.value = "programmatic";

          })
          .catch(error => {
            vm.$toastr.e(error,error);
            loading.value = false;
          })
      }
    }

    /*const allowedDatesBAK = date => {

      const tomorrow = moment().add(1, 'days').startOf('day');

      if (moment(date).isSameOrBefore(moment().startOf('day')) || moment(date).isSame(tomorrow)) {
        return false;
      }

      // Allow only Tuesdays and Thursdays
      if (![2, 4].includes(moment(date).day())) return false;

      return true;
    };*/

    const hd = new Holidays('DE');
    const currentYear = moment().year();
    const nextYear = currentYear + 1;
    const holidays = hd.getHolidays(currentYear).concat(hd.getHolidays(nextYear));
    const bundesweiteFeiertage = holidays.filter(holiday => holiday.type == 'public');
    const allowedDates = date => {
      const today = moment().startOf('day');
      let threeWeekdayDaysAhead = today.clone();
      let daysAdded = 0;

      while (daysAdded < 3) {
        threeWeekdayDaysAhead.add(1, 'days');
        if (threeWeekdayDaysAhead.day() !== 0 && threeWeekdayDaysAhead.day() !== 6) {
          daysAdded++;
        }
      }

      if (moment(date).isSameOrBefore(today)) {
        return false;
      }

      if (moment(date).isBefore(threeWeekdayDaysAhead)) {
        return false;
      }

      if (![1, 2, 3, 4, 5].includes(moment(date).day())) {
        return false;
      }

      const dateMoment = moment(date);
      const isHoliday = bundesweiteFeiertage.some(holiday => moment(holiday.date).isSame(dateMoment, 'day'));

      if (isHoliday) {
        return false;
      }

      return true;
    };


    const $vuetify = getVuetify()
    const chartOptions = ref({
      labels: ['Empfänger'],
      colors: [
        $vuetify.theme.currentTheme.primary,
        addAlpha($vuetify.theme.currentTheme.primary, 0.7),
        addAlpha($vuetify.theme.currentTheme.primary, 0.3),
        addAlpha($vuetify.theme.currentTheme.secondary, 0.1),
      ],
      chart: {
        type: 'donut',
        sparkline: {
          enabled: true,
        },
      },
      plotOptions: {
        pie: {
          donut: {
            size: '90%',
            labels: {
              show: true,
              name: {
                fontSize: '14px',
                offsetY: 25,
              },
              value: {
                fontSize: '2.125rem',
                fontWeight: 600,
                color: 'rgba(255,255,255)',
                offsetY: -15,
                formatter(value) {
                  console.log(value)
                  return `${value}`
                },
              },
              total: {
                show: false,
                label: 'Empfänger',
                color: 'rgba(255,255,255)',
                formatter(value) {
                  return `${value.globals.seriesTotals.reduce((total, num) => total + num)}`
                },
              },
            },
          },
        },
      },
    })
    const chartData = ref([""])
    const genders = ref([
      {label:'Männlich',value:'m'},
      {label:'Weiblich',value:'w'},
      {label:'Divers',value:'d'},
    ]);

    return {
      chartOptions,
      chartData,
      userTab,
      tabs,
      form,
      formRef,
      saveData,
      loading,
      genders,
      updateMainData,
      fetchNumberOfPotentialDatasets,
      numberOfPotentialDatasets,
      targetGroups,
      adMaterials,
      errorMessages,
      totalSums,
      dateRules,
      allowedDates,
      maxEmpfaengerChangeContext,
      handleMaxEmpfaenger,
      targetGroupHandler,
      selectedTargetGroup,
      adMaterialHandler,
      selectedAdMaterial,
      stringRules,
      handleCoordinatesUpdate,
      handleQrCodeCoordinatesUpdate,
      icons: {
        mdiHomeOutline,
        mdiEmailOutline,
        mdiLockOutline,
        mdiEyeOffOutline,
        mdiEyeOutline,
        mdiCalendarOutline,
        mdiClockOutline,
        mdiRefresh,
        mdiCartOutline,
        mdiShareVariantOutline,
        mdiBullseyeArrow,
        mdiCloudUpload,
      },
      validators: {
        required,
        emailValidator,
        minValueRule,
      },
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';

.user-tabs{
  display: none;
}
#user-tabs-content{
  margin:0 !important;
}

#chart-sales-overview {
  position: relative;
  top:-30px;
  .apexcharts-canvas {
    .apexcharts-text {
      &.apexcharts-datalabel-value {
        font-weight: 600;
      }
      &.apexcharts-datalabel-label {
        font-size: 1rem;
      }
    }
  }
}

.sales-overview-stats-table {
  width: 100%;
  td {
    padding-bottom: 1rem;
  }

  .stats-dot {
    padding: 0.33rem;
  }

  // Set opacity of dots
  tr {
    &:nth-of-type(1) {
      td:nth-of-type(2) {
        .stats-dot {
          opacity: 0.7;
        }
      }
    }
    &:nth-of-type(2) {
      td:nth-of-type(1) {
        .stats-dot {
          opacity: 0.5;
        }
      }
      td:nth-of-type(2) {
        .stats-dot {
          opacity: 0.15;
        }
      }
    }
  }
}

.v-card__subtitle, .v-card__text #chart-sales-overview{
  font-size: unset;
}

.v-file-input {
  flex-direction: row-reverse;
}
.v-input--selection-controls {
  margin-top: 0px;
  padding-top: 0px;
}

.v-input__append-outer {
  white-space: nowrap;
}

.gamification-tree-4,
.gamification-john-pose-2,
.gamification-tree {
  position: absolute;
}
.gamification-tree {
  top: 10%;
  right: 0;
}
.gamification-john-pose-2 {
  bottom: 0;
  right: 15%;
}
.gamification-tree-4 {
  bottom: 0;
  right: 30%;
}

.refreshIcon {
  position: absolute !important;
  right: 30px;
}

@media (max-width: 600px) {
  .gamification-tree,
  .gamification-tree-4 {
    display: none;
  }
  .gamification-john-pose-2 {
    right: 5%;
  }
}

@media (max-width: 500px) {
  .gamification-john-pose-2 {
    max-width: 70px;
  }
}

@media (max-width: 400px) {
  .greeting-title {
    font-size: 1.2rem !important;
  }
}

// rtl
.v-application {
  &.v-application--is-rtl {
    .gamification-john-pose-2 {
      right: initial;
      left: 15%;
    }
    .gamification-tree {
      right: initial;
      left: 0;
    }
    .gamification-tree-4 {
      left: 30%;
      right: initial;
    }
  }
}
.v-card > :first-child:not(.v-btn):not(.v-chip):not(.v-avatar), .v-card > .v-card__progress + :not(.v-btn):not(.v-chip):not(.v-avatar) {
  border-top-left-radius: unset;
  border-top-right-radius: unset;
}
#correctionCard *{
  color:red !important;
}

.v-autocomplete.v-select.v-input--is-focused input {
  min-width: unset;
}

</style>
